<template>

  <NuxtLink class="it row_item rating_list" :to="link">
    <div class="position" data-popup="Rating">{{ rating }}</div>

    <picture class="photo">
      <source :srcset="logo" type="image/webp">
      <source :srcset="logo" type="image/jpeg">
      <img :src="logo" alt="">
    </picture>

    <div class="name">
      {{ nm }}
    </div>



    <div v-if="country" class="country">
      <img class="logo_country" :src="'/images/country/small/' + getCountryFile(country) + '.png'" alt="">

      {{ getSubtitle }}

      <i @click.prevent.stop="toChat" v-if="chatUser" class="icon-message chat-btn"/>
    </div>

    <div class="types" v-if="types">
      {{ types.join(", ") || '' }}
    </div>

    <div class="stat">
      <div class="item reps_value" v-if="item_stat.cnt_reports">
        <div class="value">
          <i class="icon-diary"></i>
          {{ item_stat.cnt_reports }}
        </div>
        <div class="ttl">Diaries</div>
      </div>

      <div class="item growers_value" v-if="item_stat.cnt_growers">
        <div class="value">
          <i class="icon-users"/>
          {{ item_stat.cnt_growers }}
        </div>
        <div class="ttl">Growers</div>
      </div>

      <div class="item big rate_value" v-if="item_stat.avg_rate">
        <span class="star_rate"></span>
        <div class="value">
          {{ item_stat.avg_rate }}
          <span>/{{ props.rateof }}</span>
        </div>
        <div class="ttl">{{ item_stat.cnt_harvests }} Harvests </div>
      </div>
    </div>
  </NuxtLink>
</template>

<script setup>
const { hooks } = useNuxtApp()

const { t } = useI18n()

const props = defineProps({
  category: {
    type: String,
    required: false
  },
  chatUser: {
    type: Number,
    required: false
  },
  country: {
    type: String,
    required: false
  },
  diaries: {
    type: Number,
    required: false
  },
  gplant: {
    type: Number,
    required: false
  },
  growers: {
    type: Number,
    required: false
  },
  harvests: {
    type: Number,
    required: false
  },
  item_stat: {
    type: Object,
    required: false
  },
  logo: {
    type: String,
    required: true
  },
  link: {
    type: String,
    required: true
  },
  nm: {
    type: String,
    required: false
  },
  products: {
    type: Number,
    required: false
  },
  rating: {
    type: Number,
    required: false
  },
  rate: {
    type: Number,
    required: false
  },
  rateof: {
    type: Number,
    required: false,
    default: 10
  },
  types: {
    type: Array,
    required: false
  },
})

const getSubtitle = computed(() => {
  return (props.products ? props.products + ' ' : '') + (props.category ?  t('category_' + props.category) : t('universal_plurals_products'))
})

const getCountryFile = function(country) {
  return country.toLowerCase().replace(/ /g, '-');
}

const toChat = function() {
  hooks.callHook('chat:start', props.chatUser).catch(() => {
    console.log('chat:start hook');
  })

}
</script>

<style scoped>
.it {
  position: relative;
  border-radius: 3px;
  color: var(--un-text-color);
  border-bottom: 1px var(--un-element-color-gray) solid;
  content-visibility: auto;
  container: breeder_row / inline-size;

  display: grid;
  width: 100%;
  grid-template-columns: 35px fit-content(80px) fit-content(200px) auto fit-content(200px);
  grid-template-areas:
  "position logo name sp stat"
  "position logo country sp stat"
  "position logo types sp stat"
;
  align-items: center;
  gap: 0 1rem;
  padding: 0.5rem 0rem;
  break-inside: avoid;

}
.it:last-child {
  border-bottom: 0;
}

.it:hover {
  color: var(--un-text-color-light);
}

.it .sp{
  grid-area: sp;

}

.it .stat_grid{
  display: none;
}

.it .photo {
  position: relative;
  flex-shrink: 0;
  width: 70px;
  aspect-ratio: 1/1;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 13px;
  object-fit: contain;

  grid-area: logo;
}
.it .photo img{
  border-radius: 5px;
}

.it .name {
  font-size: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  grid-area: name;
}


.it .btn_chat{
  display: inline-block;
  color: var(--un-text-color-gray);
  font-size: 0.85rem;
  padding: 5px;
  border-radius: 3px;
  margin-left: 5px;
  vertical-align: top;
}
.it .btn_chat.online{
  color: var(--un-primary-back-color);
}
.it .btn_chat:hover{
  background-color: var(--un-primary-back-color);
}

.it .country {
  color: var(--un-text-color-gray);
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 180px;

  grid-area: country;
}
.it .types {
  color: var(--un-text-color-gray);

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 180px;
  text-transform: capitalize;

  grid-area: types;
}
.it .country .logo_country{
  width: 21px;
  vertical-align: bottom;
  margin-right: 10px;
}
.it .country .icon.circle.online{
  color: var(--un-primary-back-color);
}
.it .country .icon.circle{
  color: var(--un-text-color-gray);
  font-size: 0.75rem;
}

.it .stat {
  display: flex;
  position: relative;
  padding-right: 10px;
  width: fit-content;
  flex-wrap: nowrap;
  justify-content: flex-end;
  flex-shrink: 0;
  grid-area: stat;
}

.it .stat .item {
  display: inline-block;
  text-align: center;
  width: 80px;
}
.it .stat .item.rate_value {
  min-width: 75px;
  text-align: left;
}


.it .stat .item .value {
  font-size: 1rem;
  color: var(--un-text-color);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  letter-spacing: -1px;
}
.it .stat .item .value .gp,
.it .stat .item .value .gw{
  width: 18px;
  height: 18px;
  vertical-align: top;
}

.it .stat .item.rate_value .star_rate {
  float: left;
  margin-right: 7px;
}
.it .stat .item.rate_value .value span{
  font-size: 0.8rem;
  font-weight: bold;
}
.it .stat .item .ttl {
  color: var(--un-text-color-gray);
}

.it .stat .item.likes .value i{
  color: var(--un-primary-back-color);
}



.it .stat .item.empty > *:not(.ttl) {
  opacity: 0.3;
}


.it .stat .item.big .ttl {
  font-size: 0.65rem;
}
.it .stat .item.big .value {
  font-size: 1rem;
}


.it .position{
  display: inline-block;

  text-align: center;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-shrink: 0;

  font-size: 1rem;
  font-weight: bold;
  color: var(--un-text-color-gray-light);
  display: inline-block;
  height: 24px;

  grid-area: position;
  text-align: center;

}




.catalog_content{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.chat-btn{
  cursor: pointer;
  padding: 9px 10px;
  margin-top: -6px;
  margin-bottom: -10px;
}

@container pb (max-width: 500px) {
  .it{
    padding: 0.7rem 0.4rem;
    gap: 0 0.6rem;
    grid-template-columns: 25px fit-content(90px) auto fit-content(100px);
    grid-template-areas:
    "position logo name stat"
    "position logo country stat"
  ;
  }
}

@container breeder_row (max-width: 700px) {
  .it .stat .growers_value{
    display: none;
  }
  .it .photo{
    max-width: 60px;
    padding: 2px;
  }
}


@container breeder_row (max-width: 500px) {
  .it .stat .growers_value{
    display: none;
  }
  .it .photo{
    max-width: 40px;
  }
}


@container breeder_row (max-width: 500px) {
  .it .stat .item_gp{
    display: none;
  }
  .it .types{
    display: none;
  }
}

@container breeder_row (max-width: 400px) {
  .it .stat .reps_value{
    display: none;
  }
}




</style>